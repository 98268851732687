import React from 'react';
import styles from '../styles/pages/It.module.scss';
import Head from 'next/head';
import logo_and_name from '../public/svg/logo_and_name.svg';
import headerBg from '../public/images/it-header-bg.jpg';
import Image from 'next/image';

const it = () => {
  return (
    <div className={styles.page}>
      <Head>
        <title itemProp="headline">Все инженерные системы</title>
        <link rel="canonical" href="https://it.ensys.store" />
      </Head>

      <div className={styles.menu}>
        <ul>
          <li><a href="#about">О компании</a></li>
          <li><a href="#products">Продукты</a></li>
          <li><a href="#services">Услуги</a></li>
          <li><a href="#contacts">Контакты</a></li>
        </ul>
      </div>

      <div className={styles.header} style={{ backgroundImage: `url(${headerBg.src})`}}>
        <div className={styles.container}>
          <Image style={{maxWidth:'250px'}} width={345} height={118} src={logo_and_name.src} alt='' />
          <div className={styles.headerText}>ИТ решения для строительной отрасли</div>
        </div>
      </div>

      <main className={styles.container}>

        <div className={styles.block}>
          <h2 id="about">О компании</h2>
          <p>
          Компания АО “ВСЕ ИНЖЕНЕРНЫЕ СИСТЕМЫ” основана в 2023 году, и занимается разработкой ИТ решений для строительной отрасли.
          </p>
          <p>
          Нашим основным продуктом является платформа маркетплейса для покупки и продажи инженерного оборудования. Так же, мы ведём работу над созданием дополнительных сервисов для распознования строительных спецификаций, системе подбора аналогов и заменителей, платформе сбора и обработке проектного оборудования.
          </p>
          <p>
          Так же, мы можем разработать специализированный продукт под ваши задачи, если у вас есть потребность в разработки собственного униикального ИТ решения.
          </p>
        </div>

        <div className={styles.block}>
          <h2 id="products">ИТ решения для строительных и монтажных организаций</h2>
          <h3>Платформа маркетплейса</h3>
          <p>
          Ключевым продуктом работы АО “Все Инженерные системы” является технологическая платформа маркетплейса по поиску и закупке инженерного оборудования. Система полностью разработана с нуля, и включает в себя 3 больших блока - клиентский сайт (для покупателей), личный кабинет мерчанта (продавца), и административное приложение для управления всеми процессами в проекте. 
          </p>
          <p>
          На техническое решение формлена интеллектуальная собственность. Платформа так же может быть адаптирована под другие рынки и отрасли.
          </p>
          <p>
          Адрес проекта: <a href="https://ensys.store">https://ensys.store</a>
          </p>
          <p>
          <iframe
            className={styles.videoFrame}
            src="https://www.youtube.com/embed/9WlReN5lr9g?si=KmvPXGQk28Kw8CuF"
            title="ENSYS маркетплейс"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
          </p>
          <br />
          <h3>Алгоритм распознавания строительных спецификаций</h3>
          <p>
          Разпознавание строительных спецификация и проектов из форматов doc, jpeg, pdf является распространённой задачей в процессе закупки оборудования на строительный объект. Для этого мы разрабатываем модуль на основе ORC для решения этой задачи. Модуль может быть интегрирован в другие системы или использоваться какк сервис. Для приобретения лицензии - обратитесь по контактам указанным в разделе контакты.
          </p>
        </div>

        <div className={styles.block}>
          <h2 id="services">Услуги</h2>
          <p>
            Помимо приобретения лицензий на использование разработанных нашей компанией решений, вы можете обратиться за разработкой уникального продукта или системы. Для этого свяжитесь с нами удобным способом через раздел контакты.
          </p>
          <h3>Технологический стек</h3>
          <p>NodeJS, ReactJS, ExpressJS, PostgreSQL, Memcache, SOLR, NextJS, TypeScript, GraphQL</p>
        </div>

        <div className={styles.block}>
          <h2 id="contacts">Контакты</h2>
          <p>
            <div>Email: <a href='mailto:cto@ensys.store'>cto@ensys.store</a></div>
            <div>Телефон: <a href='tel:+79098014961'>+7 (909) 801-4961</a></div>
          </p>
          <h3>Реквизиты</h3>
          <p>
            <div>АКЦИОНЕРНОЕ ОБЩЕСТВО `&quot;`ВСЕ ИНЖЕНЕРНЫЕ СИСТЕМЫ`&quot;`</div>
            <div>ОГРН 1237700747283</div>
            <div>ИНН 9709101220</div>
            <div>КПП 770901001</div>
            <div>Юридический адрес: 105005, Г.МОСКВА, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ БАСМАННЫЙ, УЛ РАДИО, Д. 14 СТР. 1, ПОМЕЩ. 28П</div>
          </p>
        </div>

      </main>

    </div>
  );
};

export default it;